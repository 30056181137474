import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useDispatch, useSelector } from 'react-redux'
import { user , createinitialUser} from "../dataservices/model/user"
import { useHistory } from 'react-router-dom';
import Globals from '../dataservices/global';
const NavMenu = () => {

    const [isOpen, SetIsOpen] = React.useState(false);
    const isAuth = useSelector((state: any) => { return state.isatuthendicated })
    let expiresAt = localStorage.getItem('IsAuthendicated');
    const history = useHistory();
    const dispatch = useDispatch();
    const toggle = () => {
        SetIsOpen(!isOpen)
    }
    const Signout = () => {    
        localStorage.setItem('IsAuthendicated', "false"); 
        expiresAt = localStorage.getItem('IsAuthendicated');   
        dispatch({
            type: "LOG_OUT",
            payload: createinitialUser()
        })  
  
        dispatch({
            type: "LOG_OUT_AUTH",
            payload: false
        })
        history.push(`/`);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">Magento Connector</NavbarBrand>
                    <NavbarToggler onClick={toggle} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            {
                                expiresAt !== "true" ? (
                                    <>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/">Log In</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/register">Register</NavLink>
                                        </NavItem>
                                    </>
                                ) : (null)
                            }
                            {
                                expiresAt === "true" ? (
                                    <>                                        
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" onClick={Signout}>Log Out</NavLink>
                                        </NavItem>
                                    </>
                                ) : (null)
                            }
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );

}

export default NavMenu;

