import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { scheduledPhase, createscheduledPhase, } from "../../dataservices/model/scheduledPhase";
import { GetAllPhases, GetscheduledSync, SaveAndUpdateScheduledReports } from "../../dataservices/action/job/sync";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
import moment from "moment";
import timepicker from "react-time-picker";
import { time } from "console";
import { createscheduledSync, scheduledSync } from "../../dataservices/model/scheduledSync";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
const ScheduleComponent = () => {
    let initialphaseattr: scheduledPhase[] = new Array<scheduledPhase>();
    var intScheduledSync: scheduledSync = createscheduledSync();
    const history = useHistory();
    const [phaseAttr, setphaseAttr] = useState(initialphaseattr);
    const GoBack = () => {
        history.push(`/synchistory`);
    }

    const [scheduledSyncval, SetscheduledSync] = useState(intScheduledSync);
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());


    const [phaseValue, setphaseValue] = useState("");
    const [phaseType, setphaseType] = useState(1);
    const [isOnTime, setOnTime] = useState(true);
    const [isEveryDay, setEveryDay] = useState(false);
    const [isWeekly, setWeekly] = useState(false);
    const [isMonthly, setMonthly] = useState(false);
    const [isQuaterly, setQuaterly] = useState(false);
    const [isYearly, setYearly] = useState(false);

    const [wekdaysValue, setwekdaysValue] = useState("");
    const [wekdaysType, setwekdaysType] = useState(0);

    const [QdaysValue, setQdaysValue] = useState("");
    const [QdaysType, setQdaysType] = useState(0);
    const [syncDate, SetselectsyncDate] = useState(new Date());
    const [syncTime, SetselectsyncTime] = useState("");
    const [monDaysValue, setmondaysValue] = useState(0);

    const ChangeQDays = (e: any) => {

        switch (e.target.value) {
            case "Month1":
                setQdaysValue(e.target.value);
                setQdaysType(1);
                setwekdaysType(0);
                break;
            case "Month2":
                setQdaysValue(e.target.value);
                setQdaysType(2);
                setwekdaysType(0);
                break;
            case "Month3":
                setQdaysValue(e.target.value);
                setQdaysType(3);
                setwekdaysType(0);
                break;
            default:
                setwekdaysValue("select");
                setwekdaysType(0);
        }
    }

    const ChangeDays = (e: any) => {

        switch (e.target.value) {
            case "Sunday":
                setwekdaysValue(e.target.value);
                setwekdaysType(1);
                setQdaysType(0);
                break;
            case "Monday":
                setwekdaysValue(e.target.value);
                setwekdaysType(2);
                setQdaysType(0);
                break;
            case "Tuesday":
                setwekdaysValue(e.target.value);
                setwekdaysType(3);
                setQdaysType(0);
                break;
            case "Wednesday":
                setwekdaysValue(e.target.value);
                setwekdaysType(4);
                setQdaysType(0);
                break;
            case "Thrusday":
                setwekdaysValue(e.target.value);
                setwekdaysType(5);
                setQdaysType(0);
                break;
            case "Friday":
                setwekdaysValue(e.target.value);
                setwekdaysType(6);
                setQdaysType(0);
                break;
            case "Saturday":
                setwekdaysValue(e.target.value);
                setwekdaysType(7);
                setQdaysType(0);
                break;
            default:
                setwekdaysValue("select");
                setwekdaysType(0);
        }
    }

    const ChangeMonthDays = (e: any) => {

        switch (e.target.value) {
            case "1":
                setmondaysValue(e.target.value);

                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "2":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "3":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "4":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "5":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "6":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "7":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "8":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "9":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "10":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "11":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "12":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "13":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "14":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "15":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "16":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "17":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "18":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "19":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "20":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "21":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "22":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "23":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "24":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "25":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "26":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "27":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "28":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "29":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "30":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            case "31":
                setmondaysValue(e.target.value);
                setwekdaysValue("");
                setwekdaysType(0);
                setQdaysType(e.target.value);
                break;
            default:
                setwekdaysValue("select");
                setwekdaysType(0);
        }
    }

    const ChangePhase = (e: any) => {
        switch (e.target.value) {
            case "OnTime":
                setphaseValue(e.target.value);
                setphaseType(1);
                setOnTime(true);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);

                break;
            case "EveryDay":
                setphaseValue(e.target.value);
                setphaseType(2);
                setOnTime(false);
                setEveryDay(true);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Weekly":
                setphaseValue(e.target.value);
                setphaseType(3);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(true);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Monthly":
                setphaseValue(e.target.value);
                setphaseType(4);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(true);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Quaterly":
                setphaseValue(e.target.value);
                setphaseType(5);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(true);
                setYearly(false);
                break;
            case "Yearly":
                setphaseValue(e.target.value);
                setphaseType(6);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(true);
                break;
            default:
                setphaseValue("Select");
                setphaseType(0);
        }
    }


    const ChangePhaseVal = (name: string, e: any) => {
        e.preventDefault();
        debugger;
        switch (e.target.value) {
            case "OnTime":
                setphaseValue(e.target.value);
                setphaseType(1);
                setOnTime(true);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);

                break;
            case "EveryDay":
                setphaseValue(e.target.value);
                setphaseType(2);
                setOnTime(false);
                setEveryDay(true);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Weekly":
                setphaseValue(e.target.value);
                setphaseType(3);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(true);
                setMonthly(false);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Monthly":
                setphaseValue(e.target.value);
                setphaseType(4);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(true);
                setQuaterly(false);
                setYearly(false);
                break;
            case "Quaterly":
                setphaseValue(e.target.value);
                setphaseType(5);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(true);
                setYearly(false);
                break;
            case "Yearly":
                setphaseValue(e.target.value);
                setphaseType(6);
                setOnTime(false);
                setEveryDay(false);
                setWeekly(false);
                setMonthly(false);
                setQuaterly(false);
                setYearly(true);
                break;
            default:
                setphaseValue("Select");
                setphaseType(0);
        }
    }
    const SaveAndUpdateReports = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        let sync: scheduledSync = {
            id: 0,
            phaseid: phaseType,
            nextsyncdate: syncDate,
            ismagentoSync: false,
            days: wekdaysType.toString(),
            month: QdaysType.toString(),
            name: "",
            time: syncTime
        }

        Setisloader(load);
        SaveAndUpdateScheduledReports(sync, function (res: any) {
            let result = CreateinitialLoadermessage()
            result.isSuccess = true;
            result.message = "Scheduled Successfully;"
            Setisloader(result);
        })
    }
    const onChangeDate = (e: any) => {
        debugger;
        const newDate = new Date();
        var ntime = e.target.value;
        //var newDate = moment(new Date()).format("HH:mm");
        //var ntime = moment(new Date()).format("HH:mm")
        //if (ndate > date) {
        SetselectsyncDate(newDate);
        SetselectsyncTime(ntime);
        //alert(syncTime);
        //}
        //else {
        //    debugger;
        //    let load: Loadermessage = {
        //        message: "",
        //        isLoader: true,
        //        isSuccess: false,
        //        isfailed: false
        //    }
        //    Setisloader(load);
        //    let result = CreateinitialLoadermessage()
        //    result.isfailed = true;
        //    result.message = "Please don't select future date"
        //    Setisloader(result);
        //}
    };

    const ClosePopup = () => {
        let loads: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        };
        Setisloader(loads);
    }
    useEffect(() => {
        GetAllPhases(function (result: scheduledPhase[]) {
            if (result != null) {
                setphaseAttr(result);
            }
        });
    }, []);

    useEffect(() => {
        GetscheduledSync(false, function (result: scheduledSync) {
            if (result != null) {
                SetscheduledSync(result);
                switch (result.name) {
                    case "OnTime":
                        setphaseValue(result.name.toString());
                        setphaseType(1);
                        setOnTime(true);
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        SetselectsyncDate(result.nextsyncdate);
                        setEveryDay(false);
                        setWeekly(false);
                        setMonthly(false);
                        setQuaterly(false);
                        setYearly(false);
                        break;
                    case "EveryDay":
                        setphaseValue(result.name.toString());
                        SetselectsyncDate(result.nextsyncdate);
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        setwekdaysType(0);
                        setQdaysType(0);
                        setphaseType(2);
                        setOnTime(false);
                        setEveryDay(true);
                        setWeekly(false);
                        setMonthly(false);
                        setQuaterly(false);
                        setYearly(false);
                        break;
                    case "Weekly":
                        setphaseValue(result.name.toString());
                        setphaseType(3);
                        setwekdaysType(parseInt(result.days));
                        setQdaysType(0);
                        //alert(result.nextsyncdate);
                        //alert(moment(result.nextsyncdate).format("HH:MM"));
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        SetselectsyncDate(result.nextsyncdate);
                        setOnTime(false);
                        setEveryDay(false);
                        switch (result.days) {
                            case "1":
                                setwekdaysValue("Sunday");
                                setwekdaysType(1);
                                setQdaysType(0);
                                break;
                            case "2":
                                setwekdaysValue("Monday");
                                setwekdaysType(2);
                                setQdaysType(0);
                                break;
                            case "3":
                                setwekdaysValue("Tuesday");
                                setwekdaysType(3);
                                setQdaysType(0);
                                break;
                            case "4":
                                setwekdaysValue("Wednesday");
                                setwekdaysType(4);
                                setQdaysType(0);
                                break;
                            case "5":
                                setwekdaysValue("Thrusday");
                                setwekdaysType(5);
                                setQdaysType(0);
                                break;
                            case "6":
                                setwekdaysValue("Friday");
                                setwekdaysType(6);
                                setQdaysType(0);
                                break;
                            case "7":
                                setwekdaysValue("Saturday");
                                setwekdaysType(7);
                                setQdaysType(0);
                                break;
                            default:
                                setwekdaysValue("select");
                                setwekdaysType(0);
                        }
                        setWeekly(true);
                        setMonthly(false);
                        setQuaterly(false);
                        setYearly(false);
                        break;
                    case "Monthly":
                        setphaseValue(result.name.toString());
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        SetselectsyncDate(result.nextsyncdate);
                       
                        setmondaysValue(parseInt(result.days));
                        setwekdaysType(0);
                        setQdaysType(0);
                        setphaseType(4);
                        setOnTime(false);
                        setEveryDay(false);
                        setWeekly(false);
                        setMonthly(true);
                        setQuaterly(false);
                        setYearly(false);
                        break;
                    case "Quaterly":
                        setphaseValue(result.name.toString());
                        setphaseType(5);
                        setwekdaysType(0);
                        setQdaysType(parseInt(result.month));
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        SetselectsyncDate(result.nextsyncdate);
                        setOnTime(false);
                        setEveryDay(false);
                        setWeekly(false);
                        setMonthly(false);
                        setQuaterly(true);
                        setYearly(false);
                        break;
                    case "Yearly":
                        setphaseValue(result.name.toString());
                        setphaseType(6);
                        setwekdaysType(0);
                        setQdaysType(0);
                        SetselectsyncTime(moment(result.nextsyncdate).format("HH:mm"));
                        SetselectsyncDate(result.nextsyncdate);
                        setOnTime(false);
                        setEveryDay(false);
                        setWeekly(false);
                        setMonthly(false);
                        setQuaterly(false);
                        setYearly(true);
                        break;
                    default:
                        setphaseValue("Select");
                        setphaseType(0);
                }

            }
        });
    }, []);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 className="m-0">Sync Schedule</h1>
                        <input className="btn btn-primary" type="button" onClick={GoBack} value="Back" />
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {scheduledSyncval != null ? (<div className="mb-3">
                                <select className="form-select" aria-label="Default select example" onChange={(e) => ChangePhaseVal(scheduledSyncval.name, e)} value={phaseValue}>
                                    {phaseAttr != null
                                        ? phaseAttr.map((item, index) => (
                                            <option value={item.name}>
                                                {item.name}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>) : (<div className="mb-3">
                                <select className="form-select" aria-label="Default select example" onChange={ChangePhase} value={phaseValue}>
                                    {phaseAttr != null
                                        ? phaseAttr.map((item, index) => (
                                            <option value={item.name}>
                                                {item.name}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>)
                            }
                        </div>
                    </div>
                    <div className="row">
                        {
                            scheduledSyncval != null ? (<div className="col-md-6">
                                {isOnTime ? (<div className="mb-2"><label className="form-label">Expression</label><input type="time" value={syncTime.toString()} className="form-control" onChange={onChangeDate} id="datetime" aria-describedby="datetimehelp" />  </div>) : (null)}
                                {isEveryDay ? (<div className="mb-2"><label className="form-label">Expression</label><input type="time" value={syncTime.toString()} className="form-control" id="datetime" onChange={onChangeDate} aria-describedby="datetimehelp" /> </div>) : (null)}
                                {isWeekly ?
                                    (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="col-form-label">Days</label>
                                                    <select className="form-select" aria-label="Default select example" onChange={ChangeDays} value={wekdaysValue}>
                                                        <option value="Sunday">Sunday</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thrusday">Thrusday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Expression</label>
                                                    <input type="time" value={syncTime.toString()} className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : (null)}
                                {isMonthly ? (
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="col-form-label">date</label>
                                                <select className="form-select" aria-label="Default select example" onChange={ChangeMonthDays} value={monDaysValue}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>
                                                    <option value="21">21</option>
                                                    <option value="22">22</option>
                                                    <option value="23">23</option>
                                                    <option value="24">24</option>
                                                    <option value="25">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                    <option value="28">28</option>
                                                    <option value="29">29</option>
                                                    <option value="30">30</option>
                                                    <option value="31">31</option>
                                                </select>
                                            </div>
                                            <div className="mb-2">
                                                <label className="form-label">Expression</label>
                                                <input type="time" value={syncTime.toString()} className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                    : (null)}
                                {isQuaterly ?
                                    (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="col-form-label">Days</label>
                                                    <select className="form-select" aria-label="Default select example" onChange={ChangeQDays} value={QdaysValue}>
                                                        <option value="Month1">Month1</option>
                                                        <option value="Month2">Month2</option>
                                                        <option value="Month3">Month3</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Expression</label>
                                                    <input type="time" value={syncTime.toString()} className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : (null)}
                                {isYearly ? <div className="mb-2"><label className="form-label">Expression</label><input type="time" value={syncTime.toString()} className="form-control" id="datetime" aria-describedby="datetimehelp" /></div> : (null)}
                            </div>) : (<div className="col-md-6">

                                {isOnTime ? (<div className="mb-2"><label className="form-label">Expression</label><input type="time" className="form-control" onChange={onChangeDate} id="datetime" aria-describedby="datetimehelp" />  </div>) : (null)}
                                {isEveryDay ? (<div className="mb-2"><label className="form-label">Expression</label><input type="time" className="form-control" id="datetime" onChange={onChangeDate} aria-describedby="datetimehelp" /> </div>) : (null)}
                                {isWeekly ?
                                    (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="col-form-label">Days</label>
                                                    <select className="form-select" aria-label="Default select example" onChange={ChangeDays} value={wekdaysValue}>
                                                        <option value="Sunday">Sunday</option>
                                                        <option value="Monday">Monday</option>
                                                        <option value="Tuesday">Tuesday</option>
                                                        <option value="Wednesday">Wednesday</option>
                                                        <option value="Thrusday">Thrusday</option>
                                                        <option value="Friday">Friday</option>
                                                        <option value="Saturday">Saturday</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Expression</label>
                                                    <input type="time" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : (null)}
                                {isMonthly ? <div className="mb-2"><label className="form-label">Expression</label><input type="time" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} /></div> : (null)}
                                {isQuaterly ?
                                    (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label className="col-form-label">Days</label>
                                                    <select className="form-select" aria-label="Default select example" onChange={ChangeQDays} value={QdaysValue}>
                                                        <option value="Month1">Month1</option>
                                                        <option value="Month2">Month2</option>
                                                        <option value="Month3">Month3</option>
                                                    </select>
                                                </div>
                                                <div className="mb-2">
                                                    <label className="form-label">Expression</label>
                                                    <input type="time" className="form-control" id="datetime" aria-describedby="datetimehelp" onChange={onChangeDate} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : (null)}
                                {isYearly ? <div className="mb-2"><label className="form-label">Expression</label><input type="time" className="form-control" id="datetime" aria-describedby="datetimehelp" /></div> : (null)}
                            </div>)

                        }

                        <div className="d-flex justify-content-start align-items-center">
                            <button type="button" className="btn btn-primary" onClick={SaveAndUpdateReports}>Save</button>
                        </div>
                    </div>
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>

        </React.Fragment >
    )
}
export default ScheduleComponent;