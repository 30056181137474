import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Globals from '../dataservices/global';
import SideBar from './sidebar';
export default (props: { children?: React.ReactNode }) => (


    <React.Fragment>
        <NavMenu/>
        <Container className="mh-500">
            {props.children}
        </Container>
        <footer className="py-3 footer-bg text-center">
            <p className="m-0">Version: 0.8A</p>
        </footer>
    </React.Fragment>
);
