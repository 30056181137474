import React, { useState, useEffect } from "react";
import SideBar from "../sidebar";
import { GetWebsitelistService, GetWebsiteDetailsService, SaveWebsiteDetailsService } from "../../dataservices/action/dashboard/websitelist";
import { websitelist } from "../../dataservices/model/websitelist";
import { GetChannellistService } from "../../dataservices/action/dashboard/channellist";
import { channellist } from "../../dataservices/model/channellist";
import { allwebsites, createinitialallwebsites } from "../../dataservices/model/allwebsites";
import Loader from "../loader";
import { useHistory } from 'react-router-dom';
import AFailed from "../alretbox/failed";
const AddWebsite = () => {
    var intwebsiteslist: websitelist[] = new Array<websitelist>();
    var intchannelsiteslist: channellist[] = new Array<channellist>();
    var intWebsite: allwebsites = createinitialallwebsites();
    const [siteslist, Setsiteslist] = useState(intwebsiteslist);
    const [channellist, Setchannellist] = useState(intchannelsiteslist);
    const [seleWebsite, SetseleWebsite] = useState(intchannelsiteslist);
    const [seleChannel, SetseleChannel] = useState(intchannelsiteslist);
    const [website, Setwebsite] = useState(intWebsite);
    const [isLoader, setisLoader] = useState(false);
    const history = useHistory();
    const [isisPopup, setisPopup] = useState(false);
    const [popupmsg, setpopupmsg] = useState("")
    useEffect(() => {
        setisLoader(true);
        GetWebsitelistService(function (result: websitelist[]) {
            let lstwebsites = result.filter(function(el){
                return el.id !== 0;
              });
            Setsiteslist(lstwebsites)
            setisLoader(false);
        })
    }, [])
    useEffect(() => {
        GetChannellistService(function (result: any) {
            Setchannellist(result)
        })
    }, [])

    const GetWebsiteDetails = (e: any) => {
        setisLoader(true);
        var sleWebsite = siteslist.filter(x => x.id == parseInt(e.target.value))[0];
        let website: allwebsites = {
            magentoWebsiteId: 0,
            channelsId: 0,
            websiteId: sleWebsite.id,
            websiteCode: "",
            websiteName: sleWebsite.name,
            storeId: 0,
            storeCode: "",
            storeName: "",
            storeViewId: 0,
            storeViewCode: "",
            storeViewName: "",
            categoryId: 0,
            categoryName: "",
            channelsName: "",
            id: 0,
            message :"",
            isSuccess : false,
            deltaDate: new Date(),
            LastestSyncDeltaDate: new Date()
        }
        GetWebsiteDetailsService(website, function (result: any) {
            Setwebsite(result)
            setisLoader(false);
        })
    }
    const changeChannel = (e: any) => {
        var slecChannel = channellist.filter(x => x.resultid == parseInt(e.target.value))[0];
        let newwebsite: allwebsites = {
            magentoWebsiteId: website.magentoWebsiteId,
            channelsId: slecChannel.resultid,
            websiteId: website.websiteId,
            websiteCode: website.websiteCode,
            websiteName: website.websiteName,
            storeId: website.websiteId,
            storeCode: website.storeCode,
            storeName: website.storeName,
            storeViewId: website.storeViewId,
            storeViewCode: website.storeViewCode,
            storeViewName: website.storeViewName,
            categoryId: website.categoryId,
            categoryName: website.categoryName,
            channelsName: slecChannel.name,
            id: website.id,
            message :"",
            isSuccess : false,
            deltaDate: new Date(),
            LastestSyncDeltaDate: new Date()
        }
        Setwebsite(newwebsite)
    }

    const SaveAndUpdateWebsite = () => {
        setisLoader(true);
        SaveWebsiteDetailsService(website, function (result: allwebsites) {
            setisLoader(false);
            if (result.isSuccess) {
                history.push(`/dashboard`);
            }else{
                setisPopup(true);
                setpopupmsg(result.message);
            }
        })
    }
    const ClosePopup = () => {
        setisPopup(false);
    }

    return (
        <React.Fragment>
            {isLoader ? (<Loader isActive={isLoader} />) : (null)}
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-6">
                    <h1>Add Website</h1>
                    <div className="column">
                        <div className="box-results">
                            <h5>Megento Website Setting</h5>
                            <form>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Select Website
                                    </label>

                                    <select className="form-control" onChange={GetWebsiteDetails}>
                                        <option disabled selected value={0}>select</option>
                                        {
                                            siteslist.map((item, index) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Select Channel
                                    </label>
                                    <select className="form-control" onChange={changeChannel}>
                                        <option disabled selected value={0}>select</option>
                                        {
                                            channellist.map((item, index) => (
                                                <option value={item.resultid}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </form>
                        </div>

                        {
                            website.websiteId != 0 ? (
                                <div>
                                    <h5>Summary</h5>

                                    <div className="box-results">
                                        <div className="box-results-row1">
                                            <div className="box-results-col-lft txt-name">
                                                Website Name :
                                            </div>
                                            <div className="box-results-col-rgt">
                                                {
                                                    website.websiteName
                                                }
                                            </div>
                                        </div>
                                        <div className="box-results-row1">
                                            <div className="box-results-col-lft txt-name">
                                                Store Name :
                                            </div>
                                            <div className="box-results-col-rgt">
                                                {
                                                    website.storeName
                                                }
                                            </div>
                                        </div>
                                        <div className="box-results-row1">
                                            <div className="box-results-col-lft txt-name">
                                                Store View Name :
                                            </div>
                                            <div className="box-results-col-rgt">
                                                {
                                                    website.storeViewName
                                                }
                                            </div>
                                        </div>
                                        <div className="box-results-row1">
                                            <div className="box-results-col-lft txt-name">
                                                Channel Name :
                                            </div>
                                            <div className="box-results-col-rgt">
                                                {
                                                    website.channelsName
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm" onClick={SaveAndUpdateWebsite}>Save</button>
                                </div>
                            ) : (null)
                        }
                    </div>
                    <div onClick={ClosePopup}>
                        {
                            isisPopup ? (
                                <AFailed message={popupmsg} />
                            ) : (null)
                        }

                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
export default AddWebsite;