import React, { useState, useEffect } from "react";
import { APISettings, CreateinitialAPISettings } from '../../dataservices/model/apisettings'
import { useDispatch, useSelector } from 'react-redux'
import { GetAPISettings, GetNewApiKey, TestAPiConncetion, SaveApiSettings } from '../../dataservices/action/users/getUserApiSettins'
import { SSL_OP_COOKIE_EXCHANGE } from "constants";
import SideBar from '../sidebar'
import Globals from "../../dataservices/global";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import ASuccess from "../alretbox/success";
import { Loadermessage, CreateinitialLoadermessage } from "../../dataservices/model/loadermessage";
const Connector = () => {

    const initialSettings: APISettings[] = new Array<APISettings>();
    const [apiSettings, SetApiSettings] = useState(initialSettings);
    const [apiKey, SetApiKey] = useState("");
    const [isloader, Setisloader] = useState(CreateinitialLoadermessage());
    // const [isloader, setisloader] = useState(false);
    // const [isFailed, setisFailed] = useState(false);
    // const [isSuccess, setSuccess] = useState(false);
    // const [message, setmessage] = useState("");
    const store = useSelector((state: any) => { return state })
    const dispatch = useDispatch();



    useEffect(() => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetAPISettings(10, function (result: APISettings[]) {
            if (result != null) {
                dispatch({
                    type: "MAGENTO_CONNECTOR_COMPLETED",
                    payload: result
                })
                let page = result.filter(x => x.name == "APIKey")[0];
                SetApiKey(page.token);
                SetApiSettings(result);
            }
            let loads: Loadermessage = {
                message: "",
                isLoader: false,
                isSuccess: false,
                isfailed: false
            };
            Setisloader(loads);
        })

    }, [])

    const GetNewApi = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        GetNewApiKey(store.user.customerId, function (result: string) {
            if (result != null) {
                SetApiKey(result);
            }
            let loadc = isloader;
            loadc.isLoader = false;
            Setisloader(loadc);
        })

    }
    const chnageSettings = (e: any, type: string, entity: string) => {
        const newSettings: APISettings[] = new Array<APISettings>();
        apiSettings.forEach(element => {
            if (element.name == type) {
                switch (entity) {
                    case "url":
                        element.url = e.target.value;
                        break;
                    case "token":
                        element.token = e.target.value;
                        break;
                }
            }
            newSettings.push(element);
        })
        SetApiSettings(newSettings);
    }

    const SaveSettings = (id: number) => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        var settings = apiSettings.filter(x => x.id == id)[0];
        SaveApiSettings(settings, function (result: boolean) {
            let mesg = CreateinitialLoadermessage();
            if (result) {
                mesg.isLoader = false;
                mesg.message = "Updated successfully";
                mesg.isSuccess = true
                Setisloader(mesg);
            } else {
                mesg.isLoader = false;
                mesg.message = "Update failed";
                mesg.isfailed = true
                Setisloader(mesg);
            }
        })

    }

    const SaveConnectorURL = () => {
        let load: Loadermessage = {
            message: "",
            isLoader: true,
            isSuccess: false,
            isfailed: false
        }
        Setisloader(load);
        const inriverApap: APISettings[] = new Array<APISettings>();
        inriverApap.push(apiSettings.filter(x => x.name == "InRiverAdapter")[0]);
        inriverApap.push(apiSettings.filter(x => x.name == "InRiverTransformer")[0]);
        inriverApap.push(apiSettings.filter(x => x.name == "MagentoAdapter")[0]);
        let istrue = false;
        let i = 0;
        inriverApap.forEach(element => {
            SaveApiSettings(element, function (result: boolean) {
                if (result) {
                    istrue = true;
                    i++;
                } else {
                    istrue = false;
                    i++;
                }
                if (i === 3) {
                    if (istrue) {
                        let issuc = CreateinitialLoadermessage();
                        issuc.isLoader = false;
                        issuc.isSuccess = true;
                        issuc.isfailed = false;
                        issuc.message = "Updated successfully";
                        Setisloader(issuc)
                    } else {
                        let issuc = CreateinitialLoadermessage();
                        issuc.isLoader = false;
                        issuc.isSuccess = false;
                        issuc.isfailed = true;
                        issuc.message = "Update Failed";
                        Setisloader(issuc)
                    }
                }
            })
        });


    }
    const TestConnection = (id: number) => {
        // setisloader(true);
        var settings = apiSettings.filter(x => x.name == "APIKey")[0];
        settings.url = apiSettings.filter(x => x.name == "InRiverAdapter")[0].url;
        TestAPiConncetion(settings, function (result: any) {
            if (result) {
                let result = CreateinitialLoadermessage()
                result.isSuccess = true;
                result.message = "Test Connection succeed"
                Setisloader(result);
            } else {
                let result = CreateinitialLoadermessage()
                result.isfailed = false;
                result.message = "Test Connection Failed"
                Setisloader(result);
            }
         
        })

    }
    const TestMagentoConnection = (id: number) => {
        var settings = apiSettings.filter(x => x.name == "APIKey")[0];
        settings.url = apiSettings.filter(x => x.name == "MagentoAdapter")[0].url;
        TestAPiConncetion(settings, function (result: any) {
            if (result) {
                let result = CreateinitialLoadermessage()
                result.isSuccess = true;
                result.message = "Test Connection succeed"
                Setisloader(result);
            } else {
                let result = CreateinitialLoadermessage()
                result.isfailed = false;
                result.message = "Test Connection Failed"
                Setisloader(result);
            }
            
        })

    }


    
    const ClosePopup = () => {
        let loads: Loadermessage = {
            message: "",
            isLoader: false,
            isSuccess: false,
            isfailed: false
        };
        Setisloader(loads);
    }
    const pageset = (apikey: string) => {
        let page = apiSettings.filter(x => x.name == apikey)[0];
        if (page != null) {
            switch (apikey) {
                case "InRiver":
                    return (
                        <div className="row">
                            <div className="col-md-8">
                                <h1>
                                    InRiver API Settings
                                </h1>
                                <div className="form-group mb-3">
                                    <label className="form-label">URL</label>
                                    <input className="form-control" type="text" value={page.url} onChange={(e) => chnageSettings(e, "InRiver", "url")} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Token</label>
                                    <input className="form-control" type="text" value={page.token} onChange={(e) => chnageSettings(e, "InRiver", "token")} />
                                </div>
                                <div>
                                    <input className="btn btn-secondary float-start" type="button" value="Test Connection" onClick={(e) => TestConnection(page.id)} />
                                    <input className="btn btn-primary float-end" type="button" value="Save" onClick={(e) => SaveSettings(page.id)} />
                                </div>
                            </div>
                        </div>
                    )

                case "InRiverAdapter":
                    return (


                        <div className="form-group mb-3">
                            <label className="form-label">Inriver Adapter</label>
                            <input className="form-control" type="text" value={page.url} onChange={(e) => chnageSettings(e, "InRiverAdapter", "url")} />
                        </div>
                    )
                    break;
                case "InRiverTransformer":
                    return (
                        <div className="form-group mb-3">
                            <label className="form-label">Data Transformer</label>
                            <input className="form-control" type="text" value={page.url} onChange={(e) => chnageSettings(e, "InRiverTransformer", "url")} />
                        </div>
                    )
                    break;
                case "MagentoAdapter":
                    return (
                        <div className="form-group mb-3">
                            <label className="form-label">Magento Adapter</label>
                            <input className="form-control" type="text" value={page.url} onChange={(e) => chnageSettings(e, "MagentoAdapter", "url")} />
                        </div>
                    )
                    break;
                case "Magento":
                    return (
                        <div className="row">
                            <div className="col-md-8">
                                <h1>Magento API Settings</h1>
                                <div className="form-group mb-3">
                                    <label className="form-label">URL</label>
                                    <input className="form-control" type="text" value={page.url} onChange={(e) => chnageSettings(e, "Magento", "url")} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="form-label">Token</label>
                                    <input className="form-control" type="text" value={page.token} onChange={(e) => chnageSettings(e, "Magento", "token")} />
                                </div>
                                <div>
                                    <input className="btn btn-secondary float-start" type="button" value="Test Connection" onClick={(e) => TestMagentoConnection(page.id)}/>
                                    <input className="btn btn-primary float-end" type="button" value="Save" onClick={(e) => SaveSettings(page.id)} />
                                </div>
                            </div>
                        </div>
                    )
                    break;
            }
        }

    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                {
                    isloader.isLoader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-9">
                    {
                        apiSettings != null ? (
                            <div>
                                {
                                    <div className="row align-items-end mt-4 mb-4">
                                        <div className="col">
                                            <label className="form-label">Connector Access Key</label>
                                            <input className="form-control" type="text" value={apiKey} />
                                        </div>
                                        <div className="col">
                                            <input className="btn btn-primary" type="button" value="Generate key" onClick={GetNewApi} />
                                        </div>
                                    </div>
                                }
                                <hr></hr>
                                {
                                    pageset("InRiver")
                                }
                                <hr></hr>
                                {
                                    pageset("Magento")
                                }
                                <hr></hr>
                                <div className="row">
                                    <div className="col-md-8">
                                        <h1>Connector URL</h1>
                                        {
                                            pageset("InRiverAdapter")
                                        }
                                        {
                                            pageset("InRiverTransformer")
                                        }
                                        {
                                            pageset("MagentoAdapter")
                                        }
                                        <div>
                                            <input className="btn btn-primary float-end" type="button" value="Save" onClick={(e) => SaveConnectorURL()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (null)
                    }

                </div>

                <div onClick={ClosePopup}>
                    {
                        isloader.isfailed ? (
                            <AFailed message={isloader.message} />
                        ) : (null)
                    }
                </div>
                <div onClick={ClosePopup}>
                    {
                        isloader.isSuccess ? (
                            <ASuccess message={isloader.message} />
                        ) : (null)
                    }
                </div>
            </div>

        </React.Fragment>
    )
}
export default Connector;


