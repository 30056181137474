import React, { useEffect, useState, useRef } from "react";
import SideBar from "../sidebar";
import { ReadFiledate } from "../../dataservices/action/job/sync";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const SyncSpecificEntity = () => {

    const [filename, setFilename] = useState();

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState();
    const inputFile: any = useRef();
    const history = useHistory();

    const handleFileUpload = (e: any) => {
        const { files } = e.target;
        if (files && files.length) {
            setFilename(files[0].name);
            setSelectedFile(files[0]);
            const formData = new FormData();
            formData.append('File', files[0]);
            try {
                const response = axios({
                    method: "post",
                    url: "api/Website/UploadFile",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
            } catch (error) {
                console.log(error)
            }
        }
    }

    const OpenFileExplore = () => {
        inputFile.current.click();
    };
    const Back = () => {
        history.push(`/synchistory`);
    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-9">
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h1>Upload Entities List</h1>
                                    <div>
                                          <input className="btn btn-primary" type="button" onClick={Back} value="Back" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-md-4">
                                <input className="form-control" type="text" value={filename} />
                                <div className="form-text mt-2">
                                    <a target="_blank" href="/Files/SampleFile.xlsx" download>Download Sample File</a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <input type="button" className="btn btn-primary" onClick={OpenFileExplore} value="Browse & Upload" />
                            </div>
                        </div>
                        <input
                            ref={inputFile}
                            style={{ display: "none" }} onChange={handleFileUpload}
                            type="file"
                        />

                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SyncSpecificEntity;