import React, { useEffect, useState } from "react";
import { job, createinitialjob } from "../../dataservices/model/joblist";
import SideBar from "../sidebar";
import { useDispatch, useSelector } from 'react-redux'
import { GetJobDetailsService, CancellJobandTaskService } from "../../dataservices/action/job/joblist";
import { jobDetails, createinitialjobDetails } from "../../dataservices/model/jobDetails";
import { useHistory } from 'react-router-dom';
import Loader from "../loader";
const JobDetails = (props: any) => {
    const dispatch = useDispatch();
    var intjob: jobDetails[] = new Array<jobDetails>();
    const [joblist, SetJoblist] = useState(intjob);
    const [isloader, setisloader] = useState(false);
    const jobId = props.location.state;
    const history = useHistory();
    const Details = (id: number) => {
        props.history.push({
            pathname: '/tasklist',
            state: id
        });

    }
    const CancellJobandTask = (id: number) => {
        setisloader(true);
        CancellJobandTaskService(id, function (res: any) {
            setisloader(false);
        })

    }
    useEffect(() => {
        GetJobDetailsService(jobId, function (result: jobDetails[]) {
            SetJoblist(result);
        })

    }, [])

    const Back = () => {
        history.push(`/joblist`);
    }
    return (
        <React.Fragment>
            <div className="row">
                {
                    isloader ? (<Loader isActive={isloader} />) : (null)
                }
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h1 className="m-0">Sync Summary</h1>
                                <div>
                                    <input className="btn btn-primary me-2" type="button" onClick={(e) => { CancellJobandTask(jobId) }} value="Cancel" />
                                    <input className="btn btn-primary me-2" type="button" onClick={(e) => { Details(jobId) }} value="Sync Log" />
                                    <input className="btn btn-primary" type="button" onClick={Back} value="Back" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">
                                    Entity Name
                                </th>
                                <th scope="col">
                                    Modified Entities
                                </th>
                                <th scope="col">
                                    New Entities
                                </th>

                                <th scope="col">
                                    Status
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                joblist != null ? (
                                    joblist.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.entityName}
                                            </td>
                                            <td>
                                                {item.modified}
                                            </td>
                                            <td>
                                                {item.new}
                                            </td>

                                            <td>
                                                {item.status}
                                            </td>

                                            {/* <td>
                                                <button className="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Delete Product" onClick={(e) => Details(item.id)} ><i className="fas fa-trash"></i></button>
                                            </td> */}
                                        </tr>
                                    ))
                                ) : (

                                    <tr>
                                        <td>
                                            No Records found
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JobDetails;