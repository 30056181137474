import React, { Component, useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SideBar from "../sidebar";
import ASuccess from "../alretbox/success";
import { GetAllMagentoAttributes, GetAllAttributes, SavePIMAttributeMapping, GetmagentoAttributeMapping, DeleteMagentoAttributeMappingById } from "../../dataservices/action/Attributes/attributesMapping";
import { megantoAttributeMapping, createinitialmegantoAttributeMapping, } from "../../dataservices/model/megantoAttributeMapping";
import Loader from "../loader";
import AFailed from "../alretbox/failed";
import { debug } from "console";
function AddAttribute() {
    let initialpimattr: megantoAttributeMapping[] = new Array<megantoAttributeMapping>();
    let initialcatattr: megantoAttributeMapping[] = new Array<megantoAttributeMapping>();
    let initialAttribute: megantoAttributeMapping[] = new Array<megantoAttributeMapping>();
    const [pimAttr, setpimAttr] = useState(initialpimattr);
    const [catAttr, setcatAttr] = useState(initialcatattr);
    const [catattrid, setcatattrid] = useState(0);
    const [catValue, setcatValue] = useState("");
    const [pimValue, setpimValue] = useState("");
    const [pimattrid, setpimattrid] = useState(0);
    const [issync, setissync] = useState(false);
    const [isclose, setisclose] = useState(false);
    const [attributes, setattributes] = useState(initialAttribute);
    const [isLoader, setisLoader] = useState(false);
    const [isfailed, Setisfailed] = useState(false)
    const [isarresuss, Setisarresuss] = useState(false)
    useEffect(() => {
        setisLoader(true);
        debugger;
        GetmagentoAttributeMapping(0, function (result: megantoAttributeMapping[]) {
            if (result.length > 0) {
                setattributes(result);
            }
            else {
                var oldattr = attributes;
                let initialAttribute: megantoAttributeMapping[] = new Array<megantoAttributeMapping>();
                let item: megantoAttributeMapping = {
                    lineId: 1,
                    id: 0,
                    attributeCode: "",
                    magentoAttributeId: "",
                    magentoAttributecode: "",
                    magentoAttributeName: "",
                    isSync: false,
                    fieldTypeDisplayName: "",
                    selectedattrbute: "",
                    selecteMegentodattrbute: ""
                };
                var data = [...initialAttribute, ...oldattr]
                data.push(item);
                setattributes(data);
            }

        });
    }, []);

    useEffect(() => {

        GetAllAttributes(function (result: megantoAttributeMapping[]) {
            if (result != null) {
                setpimAttr(result);
            }
        });
    }, []);
    useEffect(() => {
        setisLoader(true);
        GetAllMagentoAttributes(function (result: megantoAttributeMapping[]) {
            if (result != null) {
                setcatAttr(result);
                setisLoader(false);
            }
        });
    }, []);

    const Changepimattr = (id: number, e: any) => {
        debugger;
        e.preventDefault();
        setisLoader(true);
        var attr = [...attributes];
        var newaattr = pimAttr.filter(x => x.attributeCode == e.target.value)[0];
        attr.forEach(element => {
            if (element.id === id) {
                element.attributeCode = newaattr.attributeCode;
                element.selectedattrbute = newaattr.attributeCode;
            }
        });
        setattributes(attr);
        SaveAttribute(id);
    };

    const Changecatattr = (id: number, e: any) => {
        debugger;
        e.preventDefault();
        setisLoader(true);
        var attr = [...attributes];
        var newaattr = catAttr.filter(x => x.attributeCode == e.target.value)[0];
        attr.forEach(element => {
            if (element.id === id) {
                element.magentoAttributecode = newaattr.attributeCode;
                element.selecteMegentodattrbute = newaattr.attributeCode;
                element.magentoAttributeId = newaattr.id.toString();
            }
        });
        setattributes(attr);
        SaveAttribute(id);

    };

    const SaveAttribute = (id: number) => {
        var attribute = attributes.filter(x => x.id == id)[0];
        SavePIMAttributeMapping(attribute, function (result: any) {
            if (result != null) {
                setattributes(result);
            } else {
                Setisfailed(true);
            }
            setisLoader(false);
        });
    };

    const DeleteAttribute = (id: number) => {
        setisLoader(true);
        var attribute = attributes.filter(x => x.id == id)[0];
        DeleteMagentoAttributeMappingById(attribute, function (result: any) {
            if (result != null) {
                setattributes(result);
            } else {
                Setisfailed(true);
            }
            setisLoader(false);
        });
    };


    const CloseLoginPopup = () => {
        setisclose(!isclose);
    };
    const ChnageisSync = () => {
        setissync(!isclose);
    };
    const AddnewAttribute = () => {
        var oldattr = attributes;
        var isNew = attributes.filter(x => x.magentoAttributeId === "0")[0];
        if (isNew == undefined) {
            let initialAttribute: megantoAttributeMapping[] = new Array<megantoAttributeMapping>();
            let item: megantoAttributeMapping = {
                lineId: oldattr.length + 1,
                id: 0,
                attributeCode: "",
                magentoAttributeId: "0",
                magentoAttributecode: "",
                magentoAttributeName: "",
                isSync: false,
                fieldTypeDisplayName: "",
                selectedattrbute: "",
                selecteMegentodattrbute: ""
            };
            var data = [...initialAttribute, ...oldattr]
            data.push(item);
            setattributes(data);
        }
        else {
            Setisfailed(true)
        }
    };
    const ClosePopup = () => {
        Setisfailed(false);
    }
    return (
        <div className="row">
            <div className="col-md-3">
                <SideBar />
            </div>
            <div className="col-md-9">
                {isLoader ? (<Loader isActive={isLoader} />) : (null)}
                <h1>Category Attributes</h1>
                <div className="attributemapping-header">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="attributemapping-heading">
                                PIM Attribute
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="attributemapping-heading">
                                Magento Attribute
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
                {attributes != null ? (
                    attributes.map((attr, index) => (
                        <React.Fragment>
                            <div className="attributemapping-body">
                                <div className="row">
                                    <div className="col-md-5">
                                        <select className="form-control" aria-label="Default select example" onChange={(e) => Changepimattr(attr.id, e)} value={attr.attributeCode} >
                                            <option disabled value="">Select</option>
                                            {pimAttr != null
                                                ? pimAttr.map((item, index) => (
                                                    <option value={item.attributeCode}>
                                                        {item.fieldTypeDisplayName}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                    </div>
                                    <div className="col-md-5">
                                        <select className="form-control" aria-label="Default select example" onChange={(e) => Changecatattr(attr.id, e)} value={attr.magentoAttributecode} >
                                            <option disabled value="">Select</option>
                                            {catAttr != null
                                                ? catAttr.map((item, index) => (
                                                    <option value={item.attributeCode}>
                                                        {item.fieldTypeDisplayName}
                                                    </option>
                                                ))
                                                : null}
                                        </select>
                                    </div>
                                    {
                                        attr.id != 0 ? (
                                            <div className="col-md-1">
                                                <span className="delete-btn">
                                                    <i className="bi bi-trash-fill" onClick={(e) => DeleteAttribute(attr.id)}></i>
                                                </span>
                                            </div>
                                        ) : (null)
                                    }


                                    {attributes.length == index + 1 ? (
                                        <div className="col-md-1">
                                            <span className="fs-4">
                                                <i
                                                    className="bi bi-plus-circle"
                                                    onClick={AddnewAttribute}
                                                ></i>
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </React.Fragment>
                    ))
                ) : (
                    <div className="attributemapping">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="attributemapping-heading">PIM Attribute</div>
                            </div>
                            <div className="col-md-1">
                                <div className="attributemapping-heading">Sync</div>
                            </div>
                            <div className="col-md-5">
                                <div className="attributemapping-heading">
                                    Magento Attribute
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <select
                                    className="form-control"
                                    aria-label="Default select example"
                                    onChange={(e) => Changepimattr(0, e)}
                                    value={pimValue}
                                >
                                    {pimAttr != null
                                        ? pimAttr.map((item, index) => (
                                            <option value={item.attributeCode}>
                                                {item.fieldTypeDisplayName}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>
                            {/* <div className="col-md-1">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        onChange={ChnageisSync}
                                    />
                                </div>
                            </div> */}
                            <div className="col-md-5">
                                <select
                                    className="form-control"
                                    aria-label="Default select example"
                                    onChange={(e) => Changecatattr(0, e)}
                                    value={catValue}
                                >
                                    {catAttr != null
                                        ? catAttr.map((item, index) => (
                                            <option value={item.magentoAttributecode}>
                                                {item.fieldTypeDisplayName}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>

                            <div className="col-md-1">
                                <span className="fs-4">
                                    <i className="bi bi-plus-circle" onClick={AddnewAttribute}></i>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
                {/* 
                <div className="row">
                    <div className="col-md-12 text-center mt-3">
                        <button type="button" className="btn btn-primary" onClick={SaveAttribute}>
                            Save
                        </button>
                    </div>
                </div> */}
            </div>
            <div onClick={CloseLoginPopup}>
                {isclose ? <ASuccess message={"Attribute saved Successfully"} /> : null}
            </div>

            <div onClick={ClosePopup}>
                {
                    isfailed ? (
                        <AFailed message={"Already New attribute Added"} />
                    ) : (null)
                }

            </div>
        </div>
    );
}

export default AddAttribute;
