import axios from "axios";
import { user } from "../../model/user";

export const GetUserLoad = async (page: number,size : number, callback: any) => {
    await axios.post("api/Account/UserList", {
        index: page,
        recordPerPage :size
    }).then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}


export const DeleteUser = async (id: number, callback: any) => {
    await axios.post("api/Account/DeleteUserById?userId="+id)
    .then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}

export const SaveEditUser = async (customerid : number,user : user, callback: any) => {
    await axios.post("api/Account/AddandEditUser?UserId="+customerid,{
       Id : user.id,
        UserName: user.userName,
        Passwordhash: user.password,
        Emailid : user.emailId
    })
    .then(res => {
        callback(res.data)
    })
        .catch(err => console.log(err))
}