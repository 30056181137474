import axios from 'axios';
import { user } from '../../model/user';



export const UserRegister = async  (userName: string, emailId: string,password : string, callback: any) => {  
       await axios.post(`api/Account/Signup`, {
            UserName: userName,
            Password: password,
            EmailId : emailId
        }).then((result:any) => {
            return callback(result.data);
        }).catch((err : any) => {
            console.log(err)
        })
}

