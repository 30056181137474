import React, { useEffect,useState } from "react";

import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import { useHistory } from 'react-router-dom';

const SideBar = () => {
    const [isSyncSubMenu, setisSyncSubMenu] = useState(false);
    const history = useHistory();
    useEffect(() => {
       let expiresAt = localStorage.getItem('IsAuthendicated');  
        if(expiresAt !=="true"){
            history.push(`/joblist`);
        }              
    }, [])

  const  Showhidesubmenu =() =>{
    setisSyncSubMenu(!isSyncSubMenu);
  }

const IsActiveMenu =() =>{
    const allWithClass = Array.from(
        document.querySelectorAll('nav-link')
      );
  
      allWithClass.forEach(element => {
        element.classList.remove("active");
      });
}

    return (
        <div>
            <div className="sidebar-section">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/dashboard" className="nav-link active">
                            Dashboard
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link to="addattribute">
                            <a className="nav-link" href="/addattribute">
                                Category Attributes
                            </a>
                        </Link>

                    </li>
                    <li className="nav-item">
                        <Link to="apisettings">
                            <a className="nav-link" href="/apiSettings">
                                API Settings
                            </a>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link"  onClick={Showhidesubmenu}>
                            <i className="bi bi-caret-right-fill"></i> Sync
                        </a>
                        {
                            isSyncSubMenu ? (<ul>
                                <li>
                                    <Link to="/synchistory" className="nav-link">InRiver Sync</Link>
                                </li>
                                <li>
                                    <Link to="/magentosynchistory" className="nav-link">Magento Sync</Link>
                                </li>
                            </ul>) : (null)
                        }
                    </li>
                    <li className="nav-item">
                        <Link to="/userindex" className="nav-link">
                            Manage Users
                        </Link>
                    </li>
                    {/*<li className="nav-item">
                        <Link to="/tasklist">
                            <a className="nav-link" href="#">
                                Task List
                            </a>
                        </Link>
                    </li>*/}
                </ul>
            </div>
        </div>
    )
}



export default SideBar;