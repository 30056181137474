import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SideBar from "../sidebar";
import { allwebsites } from "../../dataservices/model/allwebsites";
import { GetAllWebsitesService } from "../../dataservices/action/dashboard/allwebsites";
const Dashboard = () => {

    var intallwebsites: allwebsites[] = new Array<allwebsites>();
    const [allwebsites, SetAllWebsites] = useState(intallwebsites)
    const history = useHistory();

    useEffect(() => {
        GetAllWebsitesService(function (result: any) {
            SetAllWebsites(result)
        })

    }, [])
    const AddWebsite = () => {
        history.push('/addwebsite');
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <SideBar />
                </div>
                <div className="col-md-9">                    
                    <div className="d-flex justify-content-between align-items-center mb-4" >
                        <h1 className="m-0">Dashboard</h1>
                        <input className="btn btn-primary" type="button" onClick={AddWebsite} value="+ Add Website" />
                    </div>
                    <div className="row">
                        {
                            allwebsites != null ? (
                                allwebsites.map((item, index) => (                                 
                                            <div className="col-sm-6 col-md-6">
                                                <div className="box-results" key={index}>
                                                    <div className="box-results-row">
                                                        <div className="box-results-col txt-name">
                                                            Channel Name
                                                        </div>
                                                        <div className="box-results-col">
                                                            {item.channelsName}
                                                        </div>
                                                        <div className="box-results-col txt-blue">
                                                            <i className="bi bi-pencil-square"></i>
                                                        </div>
                                                    </div>
                                                    <div className="box-results-row">
                                                        <div className="box-results-col txt-name">
                                                            Website Name
                                                        </div>
                                                        <div className="box-results-col">
                                                            {item.websiteName}
                                                        </div>
                                                        <div className="box-results-col">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="box-results-row">
                                                        <div className="box-results-col txt-name">
                                                            Store Name
                                                        </div>
                                                        <div className="box-results-col">
                                                            {item.storeName}
                                                        </div>
                                                        <div className="box-results-col">
                                                           
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>                                        
                                    
                                ))
                            ) : (null)
                        }
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;